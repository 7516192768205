import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { compose } from 'lodash/fp';
import { withTranslation } from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';

import { signIn } from '../../store/actions';
import Error from '../app/Error';

import './login.css';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = { login: '', password: '' };
  }

  componentDidMount() {
    console.log('Login REACT_APP_API_URL:', process.env.REACT_APP_API_URL)
    console.log('Login REACT_APP_EUROSTUDIO_URL:', process.env.REACT_APP_EUROSTUDIO_URL)
    document.body.classList.toggle('livee-purple', true);
  }
  componentWillUnmount() {
    document.body.classList.remove('livee-purple');
  }

  handleSubmit = event => {
    event.preventDefault();
    this.props.signIn(this.state);
  };

  render() {
    const { isAuthenticated, error, isLoading, t } = this.props;

    if (isAuthenticated) return <Redirect to="/" />;
    return (
      <div className="container-fluid">
        <div className="row justify-content-md-center text-center mgt-80">
          <div className="col-md-6 col-xs-6 col-lg-3">
            <img src={require('../../assets/livee-white.png')} className="logo" alt="Logo LiveE" />
          </div>
        </div>
        <div className="row justify-content-md-center text-center mgt-80">
          <div className="col-md-6 col-xs-6 col-lg-3">
            <LoadingOverlay
              active={isLoading}
              spinner
              text={t('common:loading')}
              className="login-box"
            >
              <h2 className="title">{t('auth:title')}</h2>
              <p>{t('auth:description')}</p>
              <Error error={error} />
              <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <label htmlFor="login">{t('auth:login')}</label>
                  <input
                    type="text"
                    className="form-control"
                    id="login"
                    value={this.state.login}
                    onChange={event => this.setState({ login: event.target.value })}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="password">{t('auth:password')}</label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    value={this.state.password}
                    onChange={event => this.setState({ password: event.target.value })}
                  />
                </div>
                <button type="submit" className="btn btn-primary">
                  {t('auth:submit')}
                </button>
              </form>
            </LoadingOverlay>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ user: { isAuthenticated }, auth: { isLoading, error } }) => ({
  isAuthenticated,
  isLoading,
  error
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ signIn }, dispatch);
};

export default compose(
  withTranslation(['auth, common']),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Login);
