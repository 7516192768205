/*
 * Source: https://gist.github.com/risker/14360929f92c307feb06b146a8c379eb
 */
import React from 'react';
import PropTypes from 'prop-types';
import { iframeResizer as iframeResizerLib } from 'iframe-resizer';

export default class IframeResizer extends React.Component {
  componentDidMount() {
    this.resizeIframe(this.props);
  }
  componentWillReceiveProps(nextProps) {
    this.resizeIframe(nextProps);
  }
  componentWillUnmount() {
    if (this.iframeResizer.length && this.iframeResizer[0].iFrameResizer) {
      this.iframeResizer[0].iFrameResizer.close();
    }
  }
  resizeIframe(props) {
    const frame = this.refs.frame;
    if (!frame) return;
    if (props.iframeResizerEnable) {
      this.iframeResizer = iframeResizerLib(props.iframeResizerOptions, frame);
    }
  }
  render() {
    const { src, id, frameBorder, className, style } = this.props;
    return (
      <iframe
        ref="frame"
        title="iframe"
        src={src}
        id={id}
        frameBorder={frameBorder}
        className={className}
        style={style}
      />
    );
  }
}
IframeResizer.propTypes = {
  src: PropTypes.string,
  iframeResizerEnable: PropTypes.bool,
  iframeResizerOptions: PropTypes.object,
  id: PropTypes.string,
  frameBorder: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.object
};
IframeResizer.defaultProps = {
  // resize iframe
  iframeResizerEnable: true,
  iframeResizerOptions: {
    autoResize: true,
    checkOrigin: false
  },
  frameBorder: 0,
  style: {
    width: '100%',
    minHeight: 20
  }
};
