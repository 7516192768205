import types from './types';
import { isNil } from 'lodash/fp';
import { map } from 'lodash';
import { saveAs } from 'file-saver';

import AuthService from '../services/AuthService';
import AttendeesService from '../services/AttendeesService';
import UsersService from '../services/UsersService';
import StatisticsService from '../services/StatisticsService';
import DownloadService from '../services/DownloadService';
import ExportBadges2Service from '../services/ExportBadges2Service';
import i18n from 'i18next';

const getLanguageId = () => {
  return (i18n.language === 'fr' || i18n.language === 'french') ? 1 : 2
}

const loginSuccess = ({ user, token }) => {
  localStorage.setItem('token', token);
  localStorage.setItem('user', JSON.stringify(user));
  return user;
};

const handleError = (type, e, dispatch) => {
  console.error(e);
  const {code, message} = e;
  const payload = message || i18n.t(`common:errors.${code}`);
  dispatch({ type, payload });
};

export const ExportBadges2Actions = {
  GENERATE_CANCEL: 'generate_cancel', // cancel current queue task
  UPDATE_ZIP: 'update_zip', // update exist zip file with all users, added only new users (or create if not exist)
  CREATE_TOTAL_PDF: 'create_total_pdf', // update PDF all users file (or create if not exist)
  REGENERATE: 'regenerate' // Regenerate all own PDF file of users, create new total PDF file, create new ZIP file
} ;

// Signin using login/password using the form
export const signIn = ({ login, password }) => (dispatch, getState) => {
  const { session } = getState();
  dispatch({ type: types.LOGIN_REQUEST });
  return AuthService.signin({ login, password, sessionId: session.id })
    .then(loginSuccess)
    .then(user => {
      return dispatch({ type: types.LOGIN_SUCCESS, payload: user });
    })
    .catch(e => handleError(types.LOGIN_FAILURE, e, dispatch));
};

// Signin if the querystring contains both 'autologin' and 'token' parameters
export const autoLogin = token => dispatch => {
  dispatch({ type: types.LOGIN_REQUEST });
  localStorage.setItem('eurostudioToken', token);
  AuthService.autologin({ token })
    .then(loginSuccess)
    .then(user => {
      return dispatch({ type: types.LOGIN_SUCCESS, payload: user });
    })
    .catch(e => handleError(types.LOGIN_FAILURE, e, dispatch));
};

export const setSession = sessionId => ({
  type: types.SET_SESSION_ID,
  payload: sessionId
});

export const getAttendees = (force = false) => (dispatch, getState) => {
  const { session } = getState();

  dispatch({ type: types.ATTENDEES_REQUEST });
  return AttendeesService.getAll(session.id)
    .then(attendees => {
      console.log('attendees: ', attendees);
      return dispatch({
        type: types.ATTENDEES_SUCCESS,
        payload: attendees
      });
    })
    .catch(e => handleError(types.ATTENDEES_FAILURE, e, dispatch));
};

export const setFilteredAttendees = (attendees) => (dispatch, getState) => {
  dispatch({ type: types.FILTERED_ATTENDEES, payload: attendees });
}

export const getStatistics = () => (dispatch, getState) => {
  const { session } = getState();
  dispatch({ type: types.STATISTICS_REQUEST });
  Promise.all([
    StatisticsService.getForSession(session.id),
    StatisticsService.getForControlpoints(session.id)
  ])
    .then(([session, controlpoints]) => {
      setTimeout(() => {
        dispatch({
          type: types.STATISTICS_SUCCESS,
          payload: {
            session,
            controlpoints: controlpoints
              .filter(controlpoint => !isNil(controlpoint.name))
              .sort((a, b) => (a.main ? -1 : b.main ? 1 : 0))
          }
        });
      }, 500);
    })
    .catch(e => handleError(types.STATISTICS_FAILURE, e, dispatch));
};

export const downloadBadge = (id, prefix = '') => (dispatch, getState) => {
  const { session } = getState();
  dispatch({ type: types.DOWNLOAD_BADGE_REQUEST, payload: id });
  DownloadService.getBadge(id, session.id)
    .then(data => {
      saveAs(data, `${prefix}-${id}.pdf`);
      dispatch({ type: types.DOWNLOAD_BADGE_SUCCESS, payload: id });
    })
    .catch(e => handleError(types.DOWNLOAD_BADGE_FAILURE, e, dispatch));
};

export const downloadBadges = (attendees) => (dispatch, getState) => {
  const { session } = getState();
  const ids = map(attendees, 'badge_id');
  dispatch({ type: types.DOWNLOAD_BADGES_REQUEST, payload: ids });
  DownloadService.getBadges(attendees, session.id)
    .then(data => {
      saveAs(data, 'badges.zip');
      dispatch({ type: types.DOWNLOAD_BADGES_SUCCESS, payload: ids });
    })
    .catch(e => handleError(types.DOWNLOAD_BADGES_FAILURE, e, dispatch));
};

export const exportBadges = (type, from, to) => (dispatch, getState) => {
  const { session } = getState();
  dispatch({ type: types.EXPORT_BADGE_REQUEST });
  DownloadService.getExport(session.id, type, from, to)
    .then(data => {
      saveAs(data, `export.${type}`);
      dispatch({ type: types.EXPORT_BADGE_SUCCESS });
    })
    .catch(e => handleError(types.EXPORT_BADGE_FAILURE, e, dispatch));
};

// users
export const fetchUserFields = () => (dispatch, getState) => {
  const { session } = getState();
  dispatch({ type: types.USER_FILEDS_REQUEST });
  UsersService.getAll(session.id)
    .then(({users, fields}) => {
      dispatch({ type: types.USER_FILEDS_SUCCESS, payload: fields })})
    .catch(e => handleError(types.USER_FAILURE, e, dispatch));
}

export const addUser = (values) => (dispatch, getState) => {
  const { session } = getState();
  dispatch({ type: types.ADD_USER_REQUEST });
  return UsersService.add(session.id, values)
    .then(user => {
      dispatch({ type: types.ADD_USER_SUCCESS, payload: i18n.t('users:add_success_message') });
      setTimeout(() => {
        dispatch({ type: types.ADD_USER_CLEAR_FORM });
        dispatch(getAttendees());
      }, 2000)
    })
    .catch(e => handleError(types.USER_FAILURE, e, dispatch));
};

// export 2
export const export2GetStatus = () => (dispatch, getState) => {
  const { session } = getState();
  return ExportBadges2Service.getStatus(session.id, getLanguageId())
    .then(status => {
      dispatch({ type: types.EXPORT2_SET_STATUS, payload: status });
      if (status.task.action_processed) {
        setTimeout(() => dispatch(export2GetStatus()), 5000);
      }
    })
    .catch(e => handleError(types.EXPORT2_FAILURE, e, dispatch));
}

export const export2SetAction = (action) => (dispatch, getState) => {
  const { session } = getState();
  return ExportBadges2Service.setAction(session.id, action, getLanguageId())
    .then(status => {
      dispatch({ type: types.EXPORT2_SET_STATUS, payload: status });
      return dispatch(export2GetStatus());
    })
    .catch(e => handleError(types.EXPORT2_FAILURE, e, dispatch));
}

export const export2download = (url, type) => (dispatch, getState) => {
  const path = /badges\/\d+\/(.+$)/.exec(url);
  if (!path) {
    return Promise.reject(`Unexpected url: ${url}`);
  }
  return ExportBadges2Service.download(path[0])
    .then(data => {
      saveAs(data, path[1]);
    })
    .catch(e => handleError(types.EXPORT2_FAILURE, e, dispatch));
}

export const setAddAttendeeMode = mode => ({
  type: types.SET_ADD_ATTENDEE_MODE,
  payload: mode
});
