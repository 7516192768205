import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import fr from './locales/fr.json';
import en from './locales/en.json';

const langs = ['fr', 'en'];

const eurostudioLang = lang => {
  return (
    {
      en: 'english',
      fr: 'french'
    }[lang] || lang
  );
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    whitelist: langs,
    resources: {
      fr,
      en
    },
    // Compatibility with eurostudio:
    fallbackLng: {
      english: ['en'],
      french: ['fr'],
      default: ['fr']
    },
    ns: ['common'],
    defaultNS: 'common',
    debug: false,

    // cache: {
    //   enabled: true
    // },
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
export { eurostudioLang };
