import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'lodash/fp';

import Login from './components/auth/Login';
import App from './components/app/App';
import Add from './components/attendees/Add';
import AddUser from './components/attendees/AddUser';
import ListAttendees from './components/attendees/List';
// import BadgesExport from './components/export/Badges';
import BadgesExport2 from './components/export/Badges2';
import StatsComponent from './components/stats/Stats';

import { autoLogin, setSession, setAddAttendeeMode } from './store/actions';

const AppRoute = ({ component: Component, isAuthenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          <App>
            <Component {...props} />
          </App>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

class AppRouter extends Component {
  componentWillMount() {
    const params = new URLSearchParams(window.location.search);
    let sessionId = params.get('sessionId');
    if (!sessionId) {
      console.warn('WARNING: sessionId is not set. You need to pass sessionId={id}');
    }
    this.props.setSession(sessionId);
    const addAttendeeMode = params.get('add-attendee-mode');
    if (addAttendeeMode === 'iframe' || addAttendeeMode === 'none') {
      this.props.setAddAttendeeMode(addAttendeeMode);
    }

    // Handle autologin
    if (params.get('autologin')) {
      this.props.autoLogin(params.get('token'));
    } else {
      console.warn(
        'WARNING: Autologin not used ! The user creation for will not work. You need to pass both autologin=true&token='
      );
    }

    if (params.get('lang')) {
      this.props.i18n.changeLanguage(params.get('lang'));
    }
  }

  render() {
    const { isAuthenticated, addAttendeeMode } = this.props;
    return (
      <Router>
        <div>
          <AppRoute path="/" exact component={ListAttendees} isAuthenticated={isAuthenticated} />
          { addAttendeeMode === 'iframe' &&
          <AppRoute
            path="/attendees/add"
            component={Add}
            isAuthenticated={isAuthenticated}
          />
          }
          { addAttendeeMode === 'native' &&
          <AppRoute
            path="/attendees/add"
            component={AddUser}
            isAuthenticated={isAuthenticated}
          />
          }
          <AppRoute path="/export/" component={BadgesExport2} isAuthenticated={isAuthenticated} />
          <AppRoute
            path="/statistics/"
            component={StatsComponent}
            isAuthenticated={isAuthenticated}
          />
          <Route path="/login" component={Login} />
        </div>
      </Router>
    );
  }
}

const mapStateToProps = ({ user: { isAuthenticated }, options: { addAttendeeMode } }) => ({
  isAuthenticated,
  addAttendeeMode
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ autoLogin, setSession, setAddAttendeeMode }, dispatch);
};

export default compose(
  withTranslation(['common']),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(AppRouter);
