import HttpService from './HttpService';

class AttendeesService extends HttpService {
  getAll(sessionId) {
    return this.http
      .get(`/proxy/${sessionId}/attendees?omit=checked`)
      .then(this.onResponse)
      .then(data => data.attendees)
      .catch(this.onError);
  }
}

export default new AttendeesService();
