import React, { useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { compose } from 'lodash/fp';
import { withTranslation } from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import { eurostudioLang } from '../../i18n';

import Iframe from './Iframe';

const IframeWrapper = styled(Iframe)`
  width: 100%;
  height: 100%;
  border: none;
  overflow: hidden;
`;

const Add = ({ customer, sessionId, t, i18n }) => {
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem('eurostudioToken') || '';
  const url = `${process.env.REACT_APP_EUROSTUDIO_URL}/${customer}/${eurostudioLang(
    i18n.language
  )}/r_${sessionId}/l/BOReceptionistUsersForm?autologin=true&jwt=${token}`;

  return (
    <LoadingOverlay
      active={loading}
      spinner
      text={t('common:loading')}
      styles={{ wrapper: { height: '100%' } }}
    >
      <IframeWrapper src={url} iframeResizerOptions={{ onInit: () => setLoading(false) }} />
    </LoadingOverlay>
  );
};

const mapStateToProps = ({ user: { customer }, session: { id } }) => ({
  customer,
  sessionId: id
});

export default compose(
  withTranslation(),
  connect(mapStateToProps)
)(Add);
