import React from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

const ErrorText = styled.div`
  color: red;
  font-weight: bold;
`;

const Error = ({ t, error }) => {
  return (
    <div>
      {error !== '' && (
        <div className="row">
          <div className="col-md-12 text-center">
            <ErrorText>{ error }</ErrorText>
          </div>
        </div>
      )}
    </div>
  );
};

export default withTranslation(['common'])(Error);
