export default {
  // Session
  SET_SESSION_ID: 'SET_SESSION_ID',
  // Auth
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  // attendees :
  ATTENDEES_REQUEST: 'ATTENDEES_REQUEST',
  ATTENDEES_SUCCESS: 'ATTENDEES_SUCCESS',
  ATTENDEES_FAILURE: 'ATTENDEES_FAILURE',
  // Statistics:
  STATISTICS_REQUEST: 'STATISTICS_REQUEST',
  STATISTICS_SUCCESS: 'STATISTICS_SUCCESS',
  STATISTICS_FAILURE: 'STATISTICS_FAILURE',
  // Badge downloads:
  DOWNLOAD_BADGE_REQUEST: 'DOWNLOAD_BADGE_REQUEST',
  DOWNLOAD_BADGE_SUCCESS: 'DOWNLOAD_BADGE_SUCCESS',
  DOWNLOAD_BADGE_FAILURE: 'DOWNLOAD_BADGES_FAILURE',
  DOWNLOAD_BADGES_REQUEST: 'DOWNLOAD_BADGES_REQUEST',
  DOWNLOAD_BADGES_SUCCESS: 'DOWNLOAD_BADGES_SUCCESS',
  DOWNLOAD_BADGES_FAILURE: 'DOWNLOAD_BADGES_FAILURE',
  // Badge export:
  EXPORT_BADGE_REQUEST: 'EXPORT_BADGE_REQUEST',
  EXPORT_BADGE_SUCCESS: 'EXPORT_BADGE_SUCCESS',
  EXPORT_BADGE_FAILURE: 'EXPORT_BADGE_FAILURE',
  // filtered
  FILTERED_ATTENDEES: 'FILTERED_ATTENDEES',
  // users
  USER_FILEDS_REQUEST: 'USER_FILEDS_REQUEST',
  USER_FILEDS_SUCCESS: 'USER_FILEDS_SUCCESS',
  ADD_USER_REQUEST: 'ADD_USER_REQUEST',
  ADD_USER_SUCCESS: 'ADD_USER_SUCCESS',
  ADD_USER_CLEAR_FORM: 'ADD_USER_CLEAR_FORM',
  USER_FAILURE: 'USER_FAILURE',
  // export 2
  EXPORT2_SET_STATUS: 'EXPORT2_SET_STATUS',
  EXPORT2_SET_ACTION: 'EXPORT2_SET_ACTION',
  EXPORT2_FAILURE: 'EXPORT2_FAILURE',
  // options
  SET_ADD_ATTENDEE_MODE: 'SET_ADD_ATTENDEE_MODE'
};

