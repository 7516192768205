import React, {useEffect} from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux'

import './app.css';

const App = ({ children }) => {
  useEffect(() => {
    console.log('Main REACT_APP_API_URL:', process.env.REACT_APP_API_URL)
    console.log('Main REACT_APP_EUROSTUDIO_URL:', process.env.REACT_APP_EUROSTUDIO_URL)
  }, [])
  const { t } = useTranslation();  
  const addAttendeeMode = useSelector(state => state.options.addAttendeeMode);
  const links = [
    {
      name: t('menu:add_attendee'),
      path: '/attendees/add'
    },
    {
      name: t('menu:attendees_list'),
      path: '/'
    },
    {
      name: t('menu:export'),
      path: '/export'
    },
    {
      name: t('menu:statistics'),
      path: '/statistics'
    }
  ].slice(addAttendeeMode === 'native' || addAttendeeMode === 'iframe' ? 0 : 1) ;
  return (
    <div>
      <div className="container-fluid">
        <div className="row justify-content-md-center">
          <div className="col-10 menu d-flex justify-content-center">
            {links.map(({ name, path }) => (
              <NavLink to={path} activeClassName="active" key={name} exact>
                {name.toUpperCase()}
              </NavLink>
            ))}
          </div>
        </div>
        <div className="row justify-content-md-center">
          <div className="col-10 content">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default App;
