import React, { useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux'
import { export2GetStatus, export2SetAction, ExportBadges2Actions } from '../../store/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Error from '../app/Error';

import './badges.css';

const BadgesExport2 = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const pdf = useSelector(state => state.export2.status.pdf);
  const zip = useSelector(state => state.export2.status.zip);
  const task = useSelector(state => state.export2.status.task);
  const error = useSelector(state => state.export2.error);

  useEffect(() => {
    dispatch(export2GetStatus());
  }, []);

  const setAction = action => {
    dispatch(export2SetAction(action));
  }

  const popover = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">{t('export2:status.title')}</Popover.Title>
      <Popover.Content>
        {task.action_processed &&
          <div className="export-info">
            <div>{t('export2:status.status')}: </div>
            <div>{ task.status }</div>
            <div>{t('export2:status.progress')}: </div>
            <div>{ task.progress }%</div>
          </div>}
        {!task.action_processed && 
          <div className="export-info">
            <div>{t('export2:status.last_action')}: </div>
            <div>{ task.last_action }</div>
          </div>}
      </Popover.Content>
    </Popover>
  );

  return (
    <div style={{ padding: 20 }}>
      <div className="row">
          <div className="col-md-12 text-center">
              <div>
                <button
                  className="btn btn-primary download-button"
                  onClick={() => setAction(ExportBadges2Actions.CREATE_TOTAL_PDF)}
                  disabled={!pdf.can_updated || task.action_processed}>
                    {t('export2:generate_pdf_all')}
                </button>
                <button
                  className="btn btn-primary download-button"
                  onClick={() => setAction(ExportBadges2Actions.UPDATE_ZIP)}
                  disabled={!zip.can_updated || task.action_processed}>
                    {t('export2:generate_zip_all')}
                </button>
                <button
                  className="btn btn-primary download-button"
                  disabled={task.action_processed}
                  onClick={() => setAction(ExportBadges2Actions.REGENERATE)}>
                    {t('export2:regenerate_all')}
                </button>
              </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 text-center">
            <div>
              <a href={pdf.file_url} target="_blank" rel="noopener noreferrer" download="aaa.pdf">
                <button
                    className="btn btn-primary download-button"
                    disabled={!pdf.file_url}>
                      {t('export2:download_pdf')}
                </button>
              </a>
              <a href={zip.file_url} target="_blank" rel="noopener noreferrer" download>
                <button
                    className="btn btn-primary download-button"
                    disabled={!zip.file_url}>
                      {t('export2:download_zip')}
                </button>
              </a>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 text-center">
            <div>
              <button className="btn btn-danger download-button"
                disabled={ !task.action_processed }
                onClick={() => setAction(ExportBadges2Actions.GENERATE_CANCEL)}
              >{t('export2:cancel')}</button>
              <OverlayTrigger trigger="click" placement="right" overlay={popover}>
                <FontAwesomeIcon icon={faInfoCircle} fixedWidth className="pointer" style={{ fontSize: 24 }} />
              </OverlayTrigger>
            </div>
          </div>
        </div>
        <Error error={error} />
    </div>
  );
}

export default BadgesExport2;