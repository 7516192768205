import React from 'react';
import PropTypes from 'prop-types';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { compose } from 'lodash/fp';
import { keys, concat, filter, reduce, isEqual, isEmpty } from 'lodash';
import { withTranslation } from 'react-i18next';

import './ExcelFilter.css'

const SELECT_ALL = '~~~ALL~~~';

class ExcelFilter extends React.Component {
  
  static propTypes = {
    column: PropTypes.object.isRequired,
    onFilter: PropTypes.func.isRequired,
  }
  constructor(props) {
    super(props);
    const { t } = this.props;
    this.state = { selection: [], search: ''}
    this.handleChange = this.handleChange.bind(this);
    this.updateSelection = this.updateSelection.bind(this);
    this.selectAll = { id: SELECT_ALL, label: t('attendees:select_all'), selected: false, display: true };
  }

  componentDidUpdate(prevProps, prevState) {
    const prevOptions = prevProps.column.filter.props.options || {};
    const options = this.props.column.filter.props.options || {};
    if (!isEqual(prevOptions, options)) {
        // init filter options
        const selection = filter(keys(options), e => !isEmpty(e) && e !== 'null').map(option => ({id: option, label: option, selected: false, display: true}));
        this.setState({ selection: concat(this.selectAll, selection) });
    }
  }

  resolveSelection(selection) {
    return reduce(selection, (acc, o) => ( o.id !== SELECT_ALL && o.display && o.selected ? [...acc, o.id] : acc), []);
  }

  updateSelection(selection, search = '') {
    this.setState({ selection, search });    
    this.props.onFilter(this.resolveSelection(selection));
  }

  handleChange(e) {
    const contains = (str, word) => {
        return str.toUpperCase().indexOf(word.toUpperCase()) >= 0;
    }
    const type = e.target.type;
    if (type === 'checkbox') {
        const selection = this.state.selection.map(option => option.id === e.target.id || e.target.id === SELECT_ALL ? {...option, selected: e.target.checked} : option);
        this.updateSelection(selection, this.state.search);
    } else if (type === 'text') {
        const value = e.target.value;
        const selection = this.state.selection.map(option => {
            return {...option, display: option.id === SELECT_ALL || contains(option.id, value)}
        });
        this.updateSelection(selection, value);
    }
  }

  renderPopover() {
    const { selection, search } = this.state;
    return (
      <Popover id="popover-positioned-bottom">
        <div className='filter-container'>
          <div>
            <input onChange={ this.handleChange } value={ search }></input>
          </div>
          <hr className='search-spacer'/>
          <div className='options-container'>
            { selection.filter(o => !!o.display).map(({id, label, selected}) => (
                <div key={ id } className="form-check text-nowrap">
                    <input className="form-check-input position-static" 
                        type="checkbox" 
                        id={ id } 
                        value={ id } 
                        checked={ selected }
                        onChange={ this.handleChange } 
                    />
                    <label htmlFor={ id } className="option-label">{ label }</label>
                </div>
            )) }
          </div>
        </div>
      </Popover>
    );
  }

  render() {
    const isActive = !isEmpty(this.resolveSelection(this.state.selection));
    const overlayProps = {
      overlay: this.renderPopover(),
      placement: 'bottom',
      trigger: 'click',
    };
    return (
      <OverlayTrigger {...overlayProps} rootClose >
        <FontAwesomeIcon icon={faFilter} fixedWidth className={`pointer filter-icon ${isActive ? 'active' : ''}`} />
      </OverlayTrigger>
    );
  }
}

export default compose(withTranslation())(ExcelFilter);