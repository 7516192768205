import React, { useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux'
import i18n from 'i18next';
import { useForm } from "react-hook-form";
import { findIndex, reduce } from 'lodash';
import { fetchUserFields } from '../../store/actions';

const buildUserFields = defaultFields => {
    const translateField = (name, label) => (label || i18n.t(`users:fields.${name}`));
    const addPasswordField = (fields) => {
        const emailIndex = findIndex(fields, ['name', 'Email'])
        const passwordIndex = findIndex(fields, ['name', 'Passwd'])
        if (emailIndex >= 0 && passwordIndex < 0) {
            return [
                ...fields.slice(0, emailIndex + 1),
                { name: "Passwd", required: false, group: '', field_type: 'password', label: translateField('Passwd') },
                ...fields.slice(emailIndex + 1)
            ]
        }
        return fields;
    }
    const addPasswordConfirmField = (fields) => {
        const passwordIndex = findIndex(fields, ['name', 'Passwd']);
        if (passwordIndex >= 0) {
            return [
                ...fields.slice(0, passwordIndex + 1),
                {...fields[passwordIndex], name: "Passwd1", label: translateField('Passwd1')},
                ...fields.slice(passwordIndex + 1)
            ]
        }
        return fields;
    }
    const fields = addPasswordConfirmField(addPasswordField(defaultFields));
    return reduce(fields, (acc, f, i) => {
        const field = {...f, label: translateField(f.name, f.label)};
        // add 'Show in contacts' in the end of form
        if (i === fields.length - 1) {
            return [...acc, field,
                { name: "ShowInContacts", required: false, group: '', field_type: 'checkbox', label: translateField('ShowInContacts') },
            ];
        }
        return [...acc, field];
    }, [])
}

const Form = ({values, onSubmit}) => {
    const dispatch = useDispatch();
    const defaultFields = useSelector(state => state.users.fields);
    const fields = buildUserFields(defaultFields);
    const clear = useSelector(state => state.users.clear);

    useEffect(() => {
        dispatch(fetchUserFields());
    }, []);

    useEffect(() => {
        if (clear) {
            const defaultValues = reduce(fields, (acc, f) => ({ ...acc, [f.id]: f.type === 'checkbox' ? false : ''}), {})
            reset(defaultValues);
        }
    }, [clear]);

    const { register, handleSubmit, watch,  errors, reset } = useForm({defaultValues: values});
    // watch password for confirm comparing
    const Passwd = watch('Passwd');
    const resolveRegisterOptions = (name, required) => {
        if (name === 'Passwd1') {
            // add validation for confirm comparing
            return {validate: value => value === Passwd || 'The passwords do not match'};
        }
        return { required };
    }

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        { fields.map(({name, required, field_type, label, values: options }) => 
            <div key={name} className="form-group row">
                <label htmlFor={name} className="col-sm-4 col-form-label">{label} {required && <sup style={{ color: 'tomato'}}>*</sup>}</label>
                <div className="col-sm-8">
                    { field_type === 'checkbox' &&
                        <div className='form-check'>
                            <input className='form-check-input' id={name} name={name} type={field_type} ref={register({...resolveRegisterOptions(name, required)})} placeholder={label} />
                        </div>
                    }
                    { (field_type === 'email' || field_type === 'text' || field_type === 'textarea' || field_type === 'password') &&
                        <input className='form-control' id={name} name={name} type={field_type} ref={register({...resolveRegisterOptions(name, required)})} placeholder={label} />
                    }
                    { field_type === 'select' &&
                        <select className="form-control" id={name} name={name} ref={register({...resolveRegisterOptions(name, required)})} >
                            { !required && 
                            <option key="--none--" value={ null }></option>
                            }
                            { options.map(o => (
                                <option key={ o.OptionValueID } value={ o.OptionValueID }>{ o.Value }</option>
                            ))}
                        </select>
                    }
                    { field_type === 'multiple' &&
                        <select className="form-control" id={name} name={name} ref={register({...resolveRegisterOptions(name, required)})} multiple >
                            { !required && 
                            <option key="--none--" value={ null }></option>
                            }
                            { options.map(o => (
                                <option key={ o.OptionValueID } value={ o.OptionValueID }>{ o.Value }</option>
                            ))}
                        </select>
                    }
                    { field_type === 'radio' && 
                        <div>
                            { options.map(o => (
                            <div className="form-check" key={o.OptionValueID}>
                                <input className="form-check-input" type="radio" id={o.OptionValueID} name={name} value={o.OptionValueID} ref={register({...resolveRegisterOptions(name, required)})} />
                                <label className="form-check-label" htmlFor={o.OptionValueID}>{o.Value}</label>
                            </div>
                        ))}
                        </div>
                    }
                    {errors[name] && errors[name].type === 'required' && <span style={{color: 'tomato'}}>{ i18n.t('users:error_required') }</span>}
                    {errors[name] && errors[name].type === 'validate' && <span style={{color: 'tomato'}}>{ i18n.t('users:error_confirmation') }</span>}
                </div>
            </div>
        )}
        <div>
            <button className='btn btn-success' type='submit'>OK</button>
        </div>
      </form>
    );
}

export default Form;