import HttpService from './HttpService';

class ExportBadges2Service extends HttpService {

  download(path) {
    return this.http
    .get(`/download/${path}`, { responseType: 'blob' })
      .then(({ data }) => new Blob([data]))
      .catch(this.onError);
  }

  getStatus(sessionId, languageID) {
    return this.http
      .get(`/proxy/${sessionId}/badges2?LanguageID=${languageID}`)
      .then(this.onResponse)
      .then(res => {
        return res;
      })
      .catch(this.onError);
  }

  setAction(sessionId, action, languageID) {
    return this.http
      .post(`/proxy/${sessionId}/badges2?LanguageID=${languageID}`, { badge_action: action })
      .then(this.onResponse)
      .then(res => {
        return res;
      })
      .catch(this.onError);
  }
}

export default new ExportBadges2Service();