import HttpService from './HttpService';

class AuthService extends HttpService {
  onLoginSuccess = ({ data, headers: { authorization } }) => {
    return { user: data, token: authorization };
  };
  signin(data) {
    return this.http
      .post('/login', data)
      .then(this.onLoginSuccess)
      .catch(this.onError);
  }

  autologin(data) {
    return this.http
      .post('/autologin', data)
      .then(this.onLoginSuccess)
      .catch(this.onError);
  }
}

export default new AuthService();
