import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose, isNil } from 'lodash/fp';
import { assign, map, reduce, isEmpty, keys } from 'lodash';
import { withTranslation } from 'react-i18next';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import filterFactory, { customFilter, FILTER_TYPES, Comparator } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload, faSyncAlt, faSpinner } from '@fortawesome/free-solid-svg-icons';
import LoadingOverlay from 'react-loading-overlay';
import ExcelFilter from './ExcelFilter'
import { getAttendees, downloadBadge, setFilteredAttendees, downloadBadges } from '../../store/actions';
import Error from '../app/Error';

import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import './list.css';

const { SearchBar } = Search;

function splitInternationalPhoneNumber(number) {
  return number.replace(/(\+\d{1,3})(\d{1})(\d{2})(\d{2})(\d{2})(\d{2})/, `$1 $2 XX XX $5 $6`);
}
function splitClassicPhoneNumber(number) {
  return number.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, `$1 XX XX $4 $5`);
}

function splitPhoneNumber(number) {
  return number.indexOf('+') === -1
    ? splitClassicPhoneNumber(number)
    : splitInternationalPhoneNumber(number);
}

function showPhoneNumber(number) {
  return !isNil(number) ? splitPhoneNumber(number) : number;
}

const columnOptions = (dataField, attendees) => {
  return reduce(map(attendees, dataField).sort(Intl.Collator().compare), (acc, v) => assign(acc, {[v]: v}), {});
}
class List extends Component {
  componentDidMount() {
    if (this.props.attendees.length === 0) {
      this.props.getAttendees();
    }
  }
  handleReload = e => {
    e.preventDefault();
    this.props.getAttendees();
  };

  handleDownloadBadges = () => {
    if (!isEmpty(this.props.filtered)) {
      this.props.downloadBadges(this.props.filtered);
    }
  }

  afterFilter = (newResult, newFilters) => {
    this.props.setFilteredAttendees(isEmpty(keys(newFilters)) ? [] : newResult);
  }

  render() {
    const { attendees, t, isLoading, error, filtered } = this.props;
    const columns = [
      {
        dataField: 'hashtag',
        text: '#',
        isDummyField: true,
        headerStyle: { fontWeight: 'bold' },
        style: { textAlign: 'center', fontWeight: 'bold' },
        formatter: (cell, row, index, _) => {
          return ` ${index + 1} `;
        }
      },
      {
        dataField: 'first_name',
        text: t('attendees:table.columns.first_name'),
        filter: customFilter({
          type: FILTER_TYPES.MULTISELECT,
          comparator: Comparator.EQ,
          caseSensitive: false,
          options: columnOptions('first_name', attendees)
        }),
        filterRenderer: (onFilter, column) => (<ExcelFilter onFilter={ onFilter } column={ column } />)
      },
      {
        dataField: 'last_name',
        text: t('attendees:table.columns.last_name'),
        filter: customFilter({
          type: FILTER_TYPES.MULTISELECT,
          comparator: Comparator.EQ,
          caseSensitive: false,
          options: columnOptions('last_name', attendees)
        }),
        filterRenderer: (onFilter, column) => (<ExcelFilter onFilter={ onFilter } column={ column } />)
      },
      {
        dataField: 'company',
        text: t('attendees:table.columns.company'),
        filter: customFilter({
          type: FILTER_TYPES.MULTISELECT,
          comparator: Comparator.EQ,
          caseSensitive: false,
          options: columnOptions('company', attendees)
        }),
        filterRenderer: (onFilter, column) => (<ExcelFilter onFilter={ onFilter } column={ column } />)
      },
      {
        dataField: 'function',
        text: t('attendees:table.columns.function'),
        filter: customFilter({
          type: FILTER_TYPES.MULTISELECT,
          comparator: Comparator.EQ,
          caseSensitive: false,
          options: columnOptions('function', attendees)
        }),
        filterRenderer: (onFilter, column) => (<ExcelFilter onFilter={ onFilter } column={ column } />)
      },
      {
        dataField: 'phone',
        text: t('attendees:table.columns.phone'),
        classes: 'phone-number',
        formatter: (cell, row) => {
          return showPhoneNumber(cell);
        },
        filter: customFilter({
          type: FILTER_TYPES.MULTISELECT,
          comparator: Comparator.EQ,
          caseSensitive: false,
          options: columnOptions('phone', attendees)
        }),
        filterRenderer: (onFilter, column) => (<ExcelFilter onFilter={ onFilter } column={ column } />)
      },
      {
        dataField: 'phone_mobile',
        text: t('attendees:table.columns.phone_mobile'),
        classes: 'phone-number',
        formatter: (cell, row) => {
          return showPhoneNumber(cell);
        },
        filter: customFilter({
          type: FILTER_TYPES.MULTISELECT,
          comparator: Comparator.EQ,
          caseSensitive: false,
          options: columnOptions('phone_mobile', attendees)
        }),
        filterRenderer: (onFilter, column) => (<ExcelFilter onFilter={ onFilter } column={ column } />)
      },
      {
        dataField: 'comment',
        text: t('attendees:table.columns.comment'),
        style: { maxWidth: '40%' },
        filter: customFilter({
          type: FILTER_TYPES.MULTISELECT,
          comparator: Comparator.EQ,
          caseSensitive: false,
          options: columnOptions('comment', attendees)
        }),
        filterRenderer: (onFilter, column) => (<ExcelFilter onFilter={ onFilter } column={ column } />)
      },
      {
        dataField: 'download',
        text: t('attendees:table.columns.download'),
        isDummyField: true,
        headerStyle: { whiteSpace: 'nowrap' },
        style: { textAlign: 'center' },
        formatter: (cell, row, index, _) => {
          const { badge_id, first_name, last_name, downloading } = row;
          const prefix = `${(first_name || '').toLowerCase()}-${(last_name || '').toLowerCase()}`;
          return (
            <div>
              {downloading ? (
                <FontAwesomeIcon icon={faSpinner} spin fixedWidth style={{ fontSize: 24 }} />
              ) : (
                <FontAwesomeIcon
                  icon={faFileDownload}
                  fixedWidth
                  style={{ fontSize: 24 }}
                  onClick={() => this.props.downloadBadge(badge_id, prefix)}
                  className="pointer"
                />
              )}
            </div>
          );
        }
      }
    ];
    return (
      <LoadingOverlay
        active={isLoading}
        spinner
        text={t('common:loading')}
        styles={{ wrapper: { height: '100%' } }}
      >
        <div className="reload-btn" onClick={this.handleReload}>
          <FontAwesomeIcon icon={faSyncAlt} fixedWidth className="pointer" />
        </div>
        <ToolkitProvider keyField="badge_id" data={attendees} columns={columns} search bootstrap4>
          {props => (
            <div>
              <div className="row justify-content-md-center">
                <div className="col-md-4 mg-20">
                  <SearchBar {...props.searchProps} />
                </div>
              </div>
              { filtered.length > 0 &&
                <div className='row mg-20'>
                  <button className="btn btn-success" onClick={ this.handleDownloadBadges }>
                    {t('attendees:export_selection')}
                  </button>
                </div>
              }
              <div className="row justify-content-md-center mg-20">
                <div className="col-md-12">
                  <BootstrapTable
                    {...props.baseProps}
                    bordered={false}
                    striped={true}
                    wrapperClasses={'table-header, table-responsive'}
                    pagination={paginationFactory()}
                    filter={ filterFactory({afterFilter: this.afterFilter}) }
                  />
                  <Error error={error} />
                </div>
              </div>
            </div>
          )}
        </ToolkitProvider>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = ({ attendees: { list, isLoading, error }, filtered: { attendees }}) => ({
  attendees: list,
  isLoading,
  filtered: attendees,
  error
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ getAttendees, downloadBadge, downloadBadges, setFilteredAttendees }, dispatch);
};

export default compose(
  withTranslation(['attendees', 'common']),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(List);
