import HttpService from './HttpService';
import JSZip from "jszip";
import { forEach } from 'lodash';

class DownloadService extends HttpService {
  download(url) {
    return this.http
      .get(url, { responseType: 'blob' })
      .then(({ data }) => new Blob([data]))
      .catch(this.onError);
  }

  getBadge(id, sessionId) {
    return this.download(`/proxy/${sessionId}/badges/${id}`);
  }

  getBadges(attendees, sessionId) {
    const zip = new JSZip();
    const folder = zip.folder("badges");
    forEach(attendees, ({badge_id, first_name, last_name}) => {
      const prefix = `${(first_name || '').toLowerCase()}-${(last_name || '').toLowerCase()}`;
      folder.file(`${prefix}-${badge_id}.pdf`, this.getBadge(badge_id, sessionId));
    });
    return zip.generateAsync({ type: "blob" });
  }

  getExport(sessionId, type, from, to) {
    const url = `/proxy/${sessionId}/badges/${type}?from=${from}&to=${to}`;
    return this.download(url);
  }
}

export default new DownloadService();
