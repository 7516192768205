import types from './types';
import { assign } from 'lodash/fp';
import { isEqual, includes } from 'lodash';

const initialState = {
  session: {},
  user: {
    isAuthenticated: false
  },
  auth: {
    isLoading: false,
    error: ''
  },
  attendees: {
    list: [],
    isLoading: false,
    error: '',
    filtered: []
  },
  statistics: {
    session: {},
    controlpoints: [],
    isLoading: false,
    error: ''
  },
  export: {
    isLoading: false,
    error: ''
  },
  export2: {
    status: {
      pdf: {},
      zip: {},
      task: {}
    },
    error: ''
  },
  filtered: {
    attendees: []
  },
  users: {
    loading: false,
    fields: [],
    clear: false,
    message: '',
    error: ''
  },
  options: {
    addAttendeeMode: 'native' // 'iframe', 'none'
  }
};

export default function reducers(state = initialState, action) {
  switch (action.type) {
    // Auth/session
    case types.SET_SESSION_ID:
      return { ...state, session: { id: action.payload } };
    case types.LOGIN_REQUEST:
      return { ...state, auth: { isLoading: true, error: '' } };
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        user: { ...action.payload, isAuthenticated: true },
        auth: { isLoading: false, error: '' }
      };
    case types.LOGIN_FAILURE:
      return { ...state, auth: { isLoading: false, error: action.payload } };

    // Attendees
    case types.ATTENDEES_REQUEST:
      return { ...state, attendees: { ...state.attendees, isLoading: true } };
    case types.ATTENDEES_SUCCESS:
      return {
        ...state,
        attendees: {
          ...state.attendees,
          list: action.payload.map(attendee => {
            return assign(attendee, { downloading: false });
          }),
          isLoading: false
        }
      };
    case types.ATTENDEES_FAILURE:
      return {
        ...state,
        attendees: { ...state.attendees, isLoading: false, error: action.payload }
      };

    // Badge download:
    case types.DOWNLOAD_BADGE_REQUEST:
      return {
        ...state,
        attendees: {
          ...state.attendees,
          list: state.attendees.list.map(attendee => {
            return attendee.badge_id === action.payload
              ? assign(attendee, { downloading: true })
              : attendee;
          })
        }
      };
    case types.DOWNLOAD_BADGE_SUCCESS:
      return {
        ...state,
        attendees: {
          ...state.attendees,
          list: state.attendees.list.map(attendee => {
            return attendee.badge_id === action.payload
              ? assign(attendee, { downloading: false })
              : attendee;
          })
        }
      };
    case types.DOWNLOAD_BADGES_REQUEST:
      return {
        ...state,
        attendees: {
          ...state.attendees,
          list: state.attendees.list.map(attendee => {
            return includes(action.payload, attendee.badge_id)
              ? assign(attendee, { downloading: true })
              : attendee;
          })
        }
      };
    case types.DOWNLOAD_BADGES_SUCCESS:
      return {
        ...state,
        attendees: {
          ...state.attendees,
          list: state.attendees.list.map(attendee => {
            return includes(action.payload, attendee.badge_id)
              ? assign(attendee, { downloading: false })
              : attendee;
          })
        }
      };

    // Stats:
    case types.STATISTICS_REQUEST:
      return { ...state, statistics: { ...state.statistics, isLoading: true, error: '' } };
    case types.STATISTICS_SUCCESS:
      return {
        ...state,
        statistics: { ...state.statistics, ...action.payload, isLoading: false, error: '' }
      };
    case types.STATISTICS_FAILURE:
      return {
        ...state,
        statistics: { ...state.statistics, isLoading: false, error: action.payload }
      };

    // Export
    case types.EXPORT_BADGE_REQUEST:
      return { ...state, export: { isLoading: true, error: '' } };
    case types.EXPORT_BADGE_SUCCESS:
      return {
        ...state,
        export: { isLoading: false, error: '' }
      };
    case types.EXPORT_BADGE_FAILURE:
      return { ...state, export: { isLoading: false, error: action.payload } };

    case types.FILTERED_ATTENDEES: 
      return isEqual(state.filtered.attendees, action.payload) ? state : 
        {
          ...state,
          filtered: { ...state.filtered, attendees: action.payload }
        }
    // users
    case types.USER_FILEDS_REQUEST:
      return {
        ...state,
        users: { ...state.users, loading: true, fields: [], error: '' }
      }
    case types.USER_FILEDS_SUCCESS:
      return {
        ...state,
        users: { ...state.users, loading: false, fields: action.payload, error: '' }
      }
    case types.ADD_USER_REQUEST:
      return {
        ...state,
        users: { ...state.users, loading: true, error: '', message: '', clear: false }
      }
    case types.ADD_USER_SUCCESS:
      return {
        ...state,
        users: { ...state.users, loading: false, error: '', message: action.payload }
      }
    case types.ADD_USER_CLEAR_FORM:
      return {
        ...state,
        users: { ...state.users, error: '', message: '', clear: true }
      }
    case types.USER_FAILURE:
      return {
        ...state,
        users: { ...state.users, loading: false, error: action.payload }
      }

    // Export 2
    case types.EXPORT2_SET_STATUS:
      return {
        ...state,
        export2: { ...state.export2, status: action.payload, error: '' }
      }
    case types.EXPORT2_FAILURE:
      return {
        ...state,
        export2: { ...state.export2, error: action.payload }
      }
  
    // options
    case types.SET_ADD_ATTENDEE_MODE:
      return {
        ...state,
        options: { ...state.options, addAttendeeMode: action.payload }
      }

    default:
      return state;
  }
}
