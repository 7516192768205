import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose, isEmpty, isNil } from 'lodash/fp';
import { withTranslation } from 'react-i18next';
import { PieChart } from 'react-easy-chart';
import { Tooltip } from 'react-tippy';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment';
import 'moment/locale/fr';

import { getStatistics } from '../../store/actions';
import Error from '../app/Error';

import 'react-tippy/dist/tippy.css';
import './stats.css';

const PRIMARY_COLOR = '#00807C';
const SECONDARY_COLOR = '#a5baba';
const chartsStyle = {
  '.chart_lines': {
    strokeWidth: 0
  },
  '.pie-chart-label': {
    fontSize: '18px',
    fill: 'white'
  }
};

const ControlPointStatistics = ({
  name,
  scans,
  last_scan,
  main,
  total_users,
  t,
  language,
  index
}) => {
  const formatedDate = !isNil(last_scan)
    ? moment
        .utc(last_scan)
        .locale(language)
        .format('LLL')
    : 'N/A';
  const lessThanTwoHours = !isNil(last_scan)
    ? moment.utc(last_scan).isAfter(moment().subtract(2, 'hours'))
    : true;

  return (
    <div className="global-stats-indicator col-md-6" key={name}>
      <div className="padding-box">
        <strong>{main ? `${t('statistics:main')}: ${name}` : name}</strong>
        <div className="pie-charts">
          <Tooltip
            title={t('statistics:users_scanned', { count: scans })}
            position="bottom"
            trigger="mouseenter"
          >
            <PieChart
              // The key attribute allows us to force state reset
              // Otherwise, when loading this page a second time it crashes
              key={+new Date()}
              labels={true}
              data={[
                { key: scans + ' '.repeat(index), value: scans, color: PRIMARY_COLOR },
                {
                  value: total_users - scans,
                  color: SECONDARY_COLOR
                }
              ]}
              size={180}
              innerHoleSize={100}
              styles={chartsStyle}
            />
          </Tooltip>
        </div>
        <p
          className="control-points-scan-time"
          style={{ color: lessThanTwoHours ? 'black' : 'red' }}
        >
          {t('statistics:last_scan')}: {formatedDate}
        </p>
      </div>
    </div>
  );
};

const SessionStatistics = ({ scans, t }) => {
  return (
    <div className="global-stats-indicator col-md-6">
      <div className="padding-box">
        <strong>{t('statistics:total')}</strong>
        <div className="pie-charts">
          <Tooltip
            title={t('statistics:total_scanned', { count: scans })}
            position="bottom"
            trigger="mouseenter"
          >
            <PieChart
              // The key attribute allows us to force state reset
              // Otherwise, when loading this page a second time it crashes
              key={+new Date()}
              labels={true}
              data={[
                {
                  value: scans,
                  key: scans,
                  color: PRIMARY_COLOR
                }
              ]}
              size={180}
              innerHoleSize={100}
              styles={chartsStyle}
            />
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

class Stats extends Component {
  componentDidMount() {
    document.getElementsByClassName('content')[0].classList.toggle('content-grey', true);
    this.props.getStatistics();
  }
  componentWillUnmount() {
    document.getElementsByClassName('content')[0].classList.remove('content-grey');
  }
  render() {
    const {
      session,
      controlpoints,
      isLoading,
      t,
      i18n: { language },
      error
    } = this.props;
    return (
      <LoadingOverlay
        active={isLoading}
        spinner
        text={t('common:loading')}
        styles={{ wrapper: { height: '100%' } }}
      >
        {!isEmpty(session) ? (
          <div className="container-charts row">
            <SessionStatistics {...session} t={t} />
            {controlpoints.map((controlpoint, index) => (
              <ControlPointStatistics
                key={index}
                index={index}
                {...controlpoint}
                total_users={session.total_users}
                t={t}
                language={language}
              />
            ))}
          </div>
        ) : (
          <Error error={error} />
        )}
        {isLoading && (
          <div className="container-charts row">
            {/* Placeholder data while loading */}
            {[1, 2, 3, 4].map((_, index) => (
              <div className="global-stats-indicator col-md-6" key={index}>
                <div className="padding-box" />
              </div>
            ))}
          </div>
        )}
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = ({ statistics: { isLoading, error, session, controlpoints } }) => ({
  isLoading,
  error,
  session,
  controlpoints
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ getStatistics }, dispatch);
};

export default compose(
  withTranslation(['statistics', 'common']),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Stats);
