import React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { addUser } from '../../store/actions';
import Form from './Form';
import {useSelector, useDispatch} from 'react-redux'
import { useTranslation } from 'react-i18next';
import Error from '../app/Error';

import './users.css';

const AddUser = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector(state => state.users.loading);
  const message = useSelector(state => state.users.message);
  const error = useSelector(state => state.users.error);

  const onSubmit = values => {
    console.log('onSubmit values:', values);
    dispatch(addUser(values));
  };

  return (
    <LoadingOverlay
      active={loading}
      spinner
      text={t('common:loading')}
      styles={{ wrapper: { height: '100%' } }}
    >
      <div className='add-user-container'>
        { !!message && <div className='message'>{ message }</div> }
        <Form values={{}} onSubmit={onSubmit} />
        { !!error && <Error error={error} /> }
      </div>
    </LoadingOverlay>
  );
};

export default AddUser