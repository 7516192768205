import axios from 'axios';
import { has } from 'lodash/fp';

export default class HttpService {
  constructor() {
    this.http = axios.create({ baseURL: process.env.REACT_APP_API_URL, headers: {} });
    this.http.interceptors.request.use(
      config => {
        const token = localStorage.getItem('token') || '';
        config.headers['Authorization'] = `Bearer ${token || ''}`;
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );
  }

  onResponse({ data }) {
    if (data.error) {
      if (data.error_type) {
        // seems each new php dev provide own error pattern..
        // if we have error_type field, the error field contains error message
        if (data.error_type === 'validation') {
          return Promise.reject({ response: { status: 403 }, message: data.message});
        }
      }
      return Promise.reject({ response: { status: 400 }, message: data.message});
    }
    // Thanks to denis, now the API hasn't always the 'result' key -_-
    return has('result', data) ? data.result : data;
  }

  onError(error) {
    console.error(error);
    let code = 'network_error';
    if (error && error.response) {
      switch (error.response.status) {
        case 401:
          code = 'login_error';
          break;
        case 403:
          code = 'validation_error';
          break;
        case 500:
          code = 'server_error';
          break;
        default:
          code = 'unknown_error';
          break;
      }
    }
    return Promise.reject({ ...error, code });
  }
}
