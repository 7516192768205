import HttpService from './HttpService';

class StatisticsService extends HttpService {
  getForSession(sessionId) {
    return this.http
      .get(`/proxy/${sessionId}/statistics`)
      .then(this.onResponse)
      .catch(this.onError);
  }

  getForControlpoints(sessionId) {
    return this.http
      .get(`/proxy/${sessionId}/control_points/statistics`)
      .then(this.onResponse)
      .catch(this.onError);
  }
}

export default new StatisticsService();
