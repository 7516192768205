import { omit, isArray } from 'lodash';
import HttpService from './HttpService';


const toEurostudioUser = values => {
  return {
    user: {
      values: omit(values, 'id')
    },
    LanguageID: 1
  }
}

const fromEurostudioField = field => {
  const { values } = field;
  // for unknown reasons if option only one, values return as object, not an array with one element..
  if ((field.field_type === 'radio' || field.field_type === 'select' || field.field_type === 'multiple') && values && !isArray(values)) {
    return { ...field, values: [values]}
  }
  return field;
}

export function fromEurostudioUser(fields, user) {
  const values = fields.reduce((acc, field) => {
    const userField = user.values.find(value => {
      // for some reason, both field and labels are mixed in the user values
      return value.name === field.label || value.name === field.name
    }) || { value: '' }
    return {
      ...acc,
      [field.name]: userField.value
    }
  }, {})
  return { id: user.id, ...values }
}

class UsersService extends HttpService {

  getAll(sessionId) {
    return this.http
      .get(`/proxy/${sessionId}/users?lang=french`)
      .then(this.onResponse)
      .then(({fields, users}) => {
        const formatedUsers = users.reduce((acc, u) => ((u.id && u.values) ? [...acc, fromEurostudioUser(fields, u)] : acc), []);
        return {
          fields: fields.map(f => fromEurostudioField(f)),
          users: formatedUsers
        };
      })
      .catch(this.onError);
  }

  add(sessionId, values) {
    return this.http
      .post(`/proxy/${sessionId}/users`, toEurostudioUser(values))
      .then(this.onResponse)
      .catch(this.onError);
  }
}

export default new UsersService();